import React, { useCallback, useEffect, useState } from "react";
import {
  Input,
  Skeleton,
  Avatar,
  Typography,
  Space,
  Button,
  Modal,
  Tooltip,
  message,
  Tag,
} from "antd";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import PageHeading from "../../components/shared/PageHeading";
import { PlusCircleFilled } from "@ant-design/icons";
import { useUser } from "../../providers/user-provider";
import { userRoles } from "../../constants";
import { createThread, listThread } from "../../services/thread.service";
import { threadStatusMap, threadType } from "../../constants/thread";

const { Text } = Typography;
const { Search } = Input;

const Ticket = ({ notifications, onChatClick, isLoading, selectedChatId }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
  const [ticketSubject, setTicketSubject] = useState(""); // State for ticket subject
  const [ticketDetails, setTicketDetails] = useState(""); // State for ticket subject
  const [threads, setThreads] = useState([]);
  const [loadingTicket, setLoadingTicket] = useState(false); // State for button loading

  useEffect(() => {
    loadThreads();
  }, [searchQuery]);

  const loadThreads = useCallback(() => {
    listThread({
      page: 1,
      limit: 20,
      ...(searchQuery && { search: searchQuery }),
    })
      .then((res) => {
        setThreads(res.data);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [searchQuery]);

  const { user } = useUser();

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const truncateMessage = (message) => {
    return message.length > 20 ? `${message.substring(0, 20)}...` : message;
  };

  // Modal handling functions
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setTicketSubject(""); // Reset the ticket subject when closing the modal
    setTicketDetails("");
  };

  const handleCreateTicket = () => {
    setLoadingTicket(true); // Set loading state to true
    const data = {
      subject: ticketSubject,
      description: ticketDetails,
      type: threadType.OTHER,
    };
    createThread(data)
      .then((res) => {
        console.log(res);
        message.success("Ticket created successfully!"); // Show success message
        handleCancel();
        loadThreads();
      })
      .catch((err) => {
        console.log(err);
        message.error(err?.message || "Something went wrong!");
      })
      .finally(() => {
        setLoadingTicket(false); // Reset loading state
      });
  };

  return (
    <>
      {isLoading ? (
        <Skeleton active title={{ width: 150 }} paragraph={false} />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <PageHeading heading={"Tickets"} />
          {!(user.role === userRoles.ADMIN) && (
            <div>
              <Tooltip title="Create a new ticket">
                <div>
                  <Button
                    style={{ background: "white", border: "0" }}
                    onClick={showModal}
                  >
                    <PlusCircleFilled size={40} style={{ color: "#08F1EB" }} />
                  </Button>
                </div>
              </Tooltip>
            </div>
          )}
        </div>
      )}

      {isLoading ? (
        <Skeleton.Input
          active
          style={{ width: 300, borderRadius: 30, height: 38 }}
        />
      ) : (
        <Search
          placeholder="Search Here..."
          className="searchbtn"
          value={searchQuery}
          onChange={handleSearchChange}
          enterButton={<FaSearch color="#08F1EB" />}
          style={{
            borderRadius: 30,
            borderColor: "#ea4f50",
            marginBottom: "20px",
          }}
        />
      )}

      <div
        style={{
          height: "calc(75vh - 220px)",
          overflowY: "auto",
          marginBottom: "20px",
        }}
      >
        {isLoading
          ? Array.from({ length: 4 }).map((_, index) => (
            <div
              key={index}
              className="custom-item"
              style={{ padding: "8px 0" }}
            >
              <Space className="w-100" align="start">
                <Skeleton.Avatar active size={40} shape="circle" />
                <Space direction="vertical" size="small">
                  <Skeleton.Input active style={{ width: 100, height: 20 }} />
                  <Skeleton.Input active style={{ width: 150, height: 15 }} />
                </Space>
              </Space>
              <Space
                className="mt-1"
                align="center"
                style={{ justifyContent: "space-between", width: "100%" }}
              >
                <Skeleton.Input active style={{ width: 70, height: 15 }} />
                <Skeleton.Avatar active size={20} shape="circle" />
              </Space>
            </div>
          ))
          : threads.map((notification, index) => (
            <Link
              key={notification.id}
              className="text-decoration-none"
              onClick={() => onChatClick(notification)}
            >
              <div
                className="custom-item"
                style={{
                  padding: "8px 10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: "10px",
                  width: "100%",
                  backgroundColor: selectedChatId === notification.id ? "#e8e3e3" : "white",
                  color: selectedChatId === notification.id ? "black" : "black",
                }}
              >
                <Space size="middle" align="center">
                  <div>
                    <h4 style={{ margin: 0, fontWeight: "700" }}>
                      {`${notification?.user?.firstName} ${notification?.user?.lastName}`}
                    </h4>

                    <Text
                      style={{
                        color: selectedChatId === notification.id ? "black" : "black",
                      }}
                    >
                      {truncateMessage(notification.description || "")}
                    </Text>
                  </div>
                </Space>

                <Space direction="vertical" align="center" style={{ textAlign: "right" }}>
                  <Text
                    style={{
                      color: selectedChatId === notification.id ? "white" : "black",
                    }}
                  >
                    {notification.time}
                  </Text>

                  <Tag color={threadStatusMap[notification.status].color}>
                  {threadStatusMap[notification.status].label}
                  </Tag>
                </Space>
              </div>

              {index < threads.length - 1 && (
                <hr
                  style={{ border: "1px solid #f0f0f0", margin: "4px 0" }}
                />
              )}
            </Link>
          ))}
      </div>

      <Modal
        title="Create Ticket"
        visible={isModalVisible}
        onCancel={handleCancel}
        centered
        footer={null}
      >
        <div style={{ marginBottom: "16px" }}>
          <label
            htmlFor="ticketSubject"
            style={{ display: "block", marginBottom: "8px" }}
          >
            Subject for Ticket
          </label>
          <Input
            id="ticketSubject"
            placeholder="Enter ticket subject"
            value={ticketSubject}
            onChange={(e) => setTicketSubject(e.target.value)} // Update ticket subject state
          />
        </div>
        <div style={{ marginBottom: "16px" }}>
          <label
            htmlFor="ticketDetials"
            style={{ display: "block", marginBottom: "8px" }}
          >
            Details for Ticket
          </label>
          <Input
            id="ticketDetials"
            placeholder="Enter ticket Details"
            value={ticketDetails}
            onChange={(e) => setTicketDetails(e.target.value)} // Update ticket details state
          />
        </div>
        <div style={{ textAlign: "end" }}>
          <Button
            type="primary"
            className="glowingbtn"
            loading={loadingTicket} // Show loading state on button
            onClick={handleCreateTicket} // Handle ticket creation and close modal
          >
            Create Ticket
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Ticket;
