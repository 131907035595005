import React from 'react';
import { Spin, Typography } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

const Loader = () => {
  return (
    <div style={styles.loaderContainer}>
      <Spin
        indicator={<Loading3QuartersOutlined spin style={{ fontSize: '40px',color:"#08F1EB" }} />}
      />
      <Typography.Text style={styles.loadingText}>Loading...</Typography.Text>
    </div>
  );
};

const styles = {
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '88vh', 
  },
  loadingText: {
    marginTop: '10px',
    fontSize: '18px',
    color: '#555', 
  },
};

export default Loader;
