export const appPaths = {
  login: "/login",
  signup: "/signup",

  home: "/",
  staff: {
    home: "staff",
    dashboard: "dashboard",
    verifications: "verifications",
    verificationCard: "verification-card",
    subscription: "subscription",
    settings: "settings",
    support: "support",
  },
  agency: {
    home: "agency",
    dashboard: "dashboard",
    verifiedEmployes: "verified-employees",
    subscription: "subscription",
    barcodeScanner: "barcode-scanner",
    settings: "settings",
    support: "support",

  },
  facility: {
    dashboard: "dashboard",
    home: "facility",
    verifiedEmployes: "verified-employees",
    barcodeScanner: "barcode-scanner",
    settings: "settings",
    subscriptions: "subscription",
    assignedStaff: "assigned-employees",
    support: "support",
  },
  admin: {
    home: "admin",
    dashboard: "dashboard",
    companies: "companies",
    companyDetails: "companies/:companyId",
    verifications: "verifications",
    verificationCard: "verification-card",
    subscriptionsOverview: "subscriptions-overview",
    barcodeScanner: "barcode-scanner",
    settings: "settings",
    support: "support",
    audit: "audit-logs"
  },
};